
import Layout from "./components/Layout"
import "./components/CSS/style.css"

function App() {
  return (
    <Layout/>
  );
}

export default App;
